import { locationId, name, title, slackAlerts } from '@agp/branches/specific/lasolas'
import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, ALL_DAYS } from '@agp/constants/weekdays'
import { MEMORIAL_DAY, LABOR_DAY, ROSH_HASHANAH, YOM_KIPPUR, THANKSGIVING } from '@agp/constants/holidays-2025'
import { PROMO, MEMBER, VOUCHER } from '@agp/constants/calendar-types'
import { FACIAL, BODY_CONTOURING, LASER } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Dolce Medical Spa"
export const COMPANY_NAME_SHORT = "Dolce"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/MXBnHb9E2unG15D0fqCL/workflow/f1e52408-dd6b-4b4d-a0b3-a9d091f663f5/9ee7aa57-2364-45db-ad9a-209de9121edb.png"
export const COMPANY_ADDRESS = "800 E Broward Blvd Unit 701, Fort Lauderdale, FL 33301"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/cGgVVhws1UgVqUKW9"
export const COMPANY_PHONE = "(305) 504-7477"
export const COMPANY_WHATSAPP = "https://wa.me/13055047477"
export const COMPANY_FBMSGER = "https://m.me/dolcemedicalspalasolas"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 259
export const TIMEZONE = "America/New_York"

// passwords
export const MANAGER_PASSWORD = "az3$2ua81!"
export const STAFF_PASSWORD = "Dolce33301$"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_l9dB4z6BtfZcb4LyqQcnfCEkNxZwCmfJfRdG8aui4qh"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "758878955814733"

// location-specific config
export const TREATMENT_ROOMS = 2
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 13 // 1pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {
	[ALL_DAYS]: { maxPromoApptsPerRoom: Infinity },
	
	[SUNDAY]: { treatmentRooms: 3 },
	[MONDAY]: { end: 18 }, // 10am - 6pm
	[TUESDAY]: { end: 18, treatmentRooms: 3 }, // 10am - 6pm
	[WEDNESDAY]: { end: 18 }, // 10am - 6pm
	[THURSDAY]: { end: 18 }, // 10am - 6pm
	[FRIDAY]: { treatmentRooms: 3 },
	
	[MEMORIAL_DAY]: { open: true },
	[LABOR_DAY]: { open: true },
	[ROSH_HASHANAH]: { open: true },
	[YOM_KIPPUR]: { open: true },
	[THANKSGIVING]: { open: true },
}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "2BqJH1w2nIezOmTO0Ald",
		name: "Medical Facial Promo",
		path: "facial-promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "pmem1jBA0rJhpcSUJcm2",
		name: "Medical Facial (Voucher)",
		path: "facial-voucher",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "AFB9gfZhH2LLc8gjD1YT",
		name: "Medical Facial (Members)",
		path: "facial-members",
	},
	{
		type: PROMO,
		service: BODY_CONTOURING,
		id: "LzvcWHWOmEWYWMlY7WSL",
		name: "Body Contouring Promo",
		path: "bc-promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: BODY_CONTOURING,
		id: "U0ThJauVNCSwb91tfU4I",
		name: "Body Contouring (Voucher)",
		path: "bc-voucher",
	},
	{
		type: MEMBER,
		service: BODY_CONTOURING,
		id: "3opShBU13b1tzXV25xnf",
		name: "Body Contouring (Members)",
		path: "bc-members",
	},
	{
		type: PROMO,
		service: LASER,
		id: "wkmabgApQjj865GnOwuK",
		name: "Laser Hair Removal Promo",
		path: "laser-promo",
		public: true,
	},
	{
		type: MEMBER,
		service: LASER,
		id: "YwqgJ4tMl6W19ChtAP1I",
		name: "Laser Hair Removal (Members)",
		path: "laser-members",
	},
]