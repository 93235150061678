import { locationId, name, title, slackAlerts } from '@agp/branches/specific/knoxville'
import { PROMO, MEMBER, VOUCHER } from '@agp/constants/calendar-types'
import { FACIAL } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Dolce Medical Spa"
export const COMPANY_NAME_SHORT = "Dolce"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/MXBnHb9E2unG15D0fqCL/workflow/f1e52408-dd6b-4b4d-a0b3-a9d091f663f5/9ee7aa57-2364-45db-ad9a-209de9121edb.png"
export const COMPANY_ADDRESS = "120 Huxley Rd unit 102, Knoxville, TN 37922"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/7MtQpKGL785GBJZp7"
export const COMPANY_PHONE = "(865) 658-1018"
export const COMPANY_WHATSAPP = "https://wa.me/18656581018"
export const COMPANY_FBMSGER = "https://m.me/dolcemedicalspaknoxville"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 411
export const TIMEZONE = "America/New_York"

// passwords
export const MANAGER_PASSWORD = "az3$2ua81!"
export const STAFF_PASSWORD = "Dolce37922$"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_lI3NvExrwOqJwZzyNDNbCYTGuidYExnWCIn9LFNYcte"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "735766492071463"

// location-specific config
export const TREATMENT_ROOMS = 3
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 14 // 2pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "kwSwYw1hADpdsdeC7jnD",
		name: "Medical Facial Promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "L3cxS0rf09Tnx1kxLmYF",
		name: "Medical Facial (Voucher)",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "5wDuL0LF80rI6L97ojfN",
		name: "Medical Facial (Members)",
	},
]