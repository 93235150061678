import { locationId, name, title, slackAlerts } from '@agp/branches/specific/miami'
import { PROMO, MEMBER, VOUCHER } from '@agp/constants/calendar-types'
import { FACIAL, BODY_CONTOURING } from '@agp/constants/service-types'
import { APPLICABLE_FOR_SPECIFIC_RANGE } from '@agp/constants/misc'
import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, ALL_DAYS } from '@agp/constants/weekdays'

// company info
export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Dolce Medical Spa"
export const COMPANY_NAME_SHORT = "Dolce"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/MXBnHb9E2unG15D0fqCL/workflow/f1e52408-dd6b-4b4d-a0b3-a9d091f663f5/9ee7aa57-2364-45db-ad9a-209de9121edb.png"
export const COMPANY_ADDRESS = "2929 SW 3rd Ave unit 610, Miami, FL 33129"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/nYwZKrjHcjLuYYkU7"
export const COMPANY_PHONE = "(786) 305-8898"
export const COMPANY_WHATSAPP = "https://wa.me/17863058898"
export const COMPANY_FBMSGER = "https://m.me/NjMedical"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 416
export const TIMEZONE = "America/New_York"

// passwords
export const MANAGER_PASSWORD = "xJs3$2"
export const STAFF_PASSWORD = "Dolce33129$"
export const ESTHETICIAN_PASSWORD = "$Esties33129$"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_96egUwRfYzEpH6daPP1xTEJE6bd29gpr89M0ZoNyUHU"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "277768065378843"

// location-specific config
export const TREATMENT_ROOMS = 3
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 13 // 2pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const DISALLOW_SAME_DAY_APPOINTMENTS = true
export const OPERATION_OVERRIDES = {
	[ALL_DAYS]: {maxPromoApptsPerRoom: 3},
	// temporary overrides
	["2025-02-12"] : {
		[MEMBER]: {maxPromoApptsPerRoom: 0, [APPLICABLE_FOR_SPECIFIC_RANGE]: [10, 14]},
		[PROMO]: {start: 14, maxPromoApptsPerRoom: 3},
		treatmentRooms: 2,
		end: 18,
	},
	["2025-02-13"] : {
		[MEMBER]: {maxPromoApptsPerRoom: 0, [APPLICABLE_FOR_SPECIFIC_RANGE]: [10, 14]},
		[PROMO]: {start: 14, maxPromoApptsPerRoom: 3},
		treatmentRooms: 2,
	},
	["2025-02-14"] : {
		treatmentRooms: 2,
	},
}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "cm00JEgoBHuspgVFuUjM",
		name: "Medical Facial Promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "GWbuRhNAP3lZJUQdUzpb",
		name: "Medical Facial (Voucher 💞)",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "yofT2uFxKNGmJM2dFyyV",
		name: "Medical Facial (Members 🔁)",
	},
	{
		type: PROMO,
		service: BODY_CONTOURING,
		id: "cm00JEgoBHuspgVFuUjM",
		name: "Body Contouring Promo",
		public: true,
	},
]